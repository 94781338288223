export function uploadAvatar() {
  console.log("uploading");
}

// export function showWidget() {
//   let widget = window.cloudinary.createUploadWidget(
//     {
//       cloudName: "dzjvfg4wt",
//       uploadPreset: "ml_default",
//     },
//     (error, result) => {
//       if (!error && result && result.event === "success") {
//         console.log("RESULT.INFO.URL", result.info.url);
//         setImageUrl(result.info.secure_url);
//       }
//     }
//   );
//   widget.open();
// }
